import sparta1 from "./../images/Sparta/IMG_01.jpg"
import sparta2 from "./../images/Sparta/IMG_02.jpg"
import sparta3 from "./../images/Sparta/IMG_03.jpg"
import sparta4 from "./../images/Sparta/IMG_04.jpg"
import sparta5 from "./../images/Sparta/IMG_05.jpg"
import sparta6 from "./../images/Sparta/IMG_06.jpg"
import sparta7 from "./../images/Sparta/IMG_07.jpg"
import sparta8 from "./../images/Sparta/IMG_08.jpg"
import sparta9 from "./../images/Sparta/IMG_09.jpg"
import sparta10 from "./../images/Sparta/IMG_10.jpg"
import sparta11 from "./../images/Sparta/IMG_11.jpg"
import sparta12 from "./../images/Sparta/IMG_12.jpg"

import Oxshott6Bed1 from "./../images/Oxshott6Bed/IMG_01.WEBP"
import Oxshott6Bed2 from "./../images/Oxshott6Bed/IMG_02.WEBP"
import Oxshott6Bed3 from "./../images/Oxshott6Bed/IMG_03.WEBP"
import Oxshott6Bed4 from "./../images/Oxshott6Bed/IMG_04.WEBP"
import Oxshott6Bed5 from "./../images/Oxshott6Bed/IMG_05.WEBP"
import Oxshott6Bed6 from "./../images/Oxshott6Bed/IMG_06.WEBP"
import Oxshott6Bed7 from "./../images/Oxshott6Bed/IMG_07.WEBP"
import Oxshott6Bed8 from "./../images/Oxshott6Bed/IMG_08.WEBP"
import Oxshott6Bed9 from "./../images/Oxshott6Bed/IMG_09.WEBP"
import Oxshott6Bed10 from "./../images/Oxshott6Bed/IMG_10.WEBP"
import Oxshott6Bed11 from "./../images/Oxshott6Bed/IMG_11.WEBP"
import Oxshott6Bed12 from "./../images/Oxshott6Bed/IMG_12.WEBP"
import Oxshott6Bed13 from "./../images/Oxshott6Bed/IMG_13.WEBP"
import Oxshott6Bed14 from "./../images/Oxshott6Bed/IMG_14.jpg"
import Oxshott6Bed15 from "./../images/Oxshott6Bed/IMG_15.jpg"

import Mauritius2Bed1 from "./../images/Mauritius2Bed/IMG_01.jpg"
import Mauritius2Bed2 from "./../images/Mauritius2Bed/IMG_02.jpg"
import Mauritius2Bed3 from "./../images/Mauritius2Bed/IMG_03.jpg"
import Mauritius2Bed4 from "./../images/Mauritius2Bed/IMG_04.jpg"
import Mauritius2Bed5 from "./../images/Mauritius2Bed/IMG_05.jpg"
import Mauritius2Bed6 from "./../images/Mauritius2Bed/IMG_06.jpg"
import Mauritius2Bed7 from "./../images/Mauritius2Bed/IMG_07.jpg"
import Mauritius2Bed8 from "./../images/Mauritius2Bed/IMG_08.jpg"
import Mauritius2Bed9 from "./../images/Mauritius2Bed/IMG_09.jpg"
import Mauritius2Bed10 from "./../images/Mauritius2Bed/IMG_10.jpg"
import Mauritius2Bed11 from "./../images/Mauritius2Bed/IMG_11.jpg"
import Mauritius2Bed12 from "./../images/Mauritius2Bed/IMG_12.jpg"
import Mauritius2Bed13 from "./../images/Mauritius2Bed/IMG_13.jpg"
import Mauritius2Bed14 from "./../images/Mauritius2Bed/IMG_14.jpg"

import SafariLodge1 from "./../images/SafariLodge/IMG_01.jpg"
import SafariLodge2 from "./../images/SafariLodge/IMG_02.jpg"
import SafariLodge3 from "./../images/SafariLodge/IMG_03.jpg"
import SafariLodge4 from "./../images/SafariLodge/IMG_04.jpg"
import SafariLodge5 from "./../images/SafariLodge/IMG_05.jpg"
import SafariLodge6 from "./../images/SafariLodge/IMG_06.jpg"
import SafariLodge7 from "./../images/SafariLodge/IMG_07.jpg"
import SafariLodge8 from "./../images/SafariLodge/IMG_08.jpg"
import SafariLodge9 from "./../images/SafariLodge/IMG_09.jpg"
import SafariLodge10 from "./../images/SafariLodge/IMG_10.jpg"
import SafariLodge11 from "./../images/SafariLodge/IMG_11.jpg"
import SafariLodge12 from "./../images/SafariLodge/IMG_12.jpg"
import SafariLodge13 from "./../images/SafariLodge/IMG_13.jpg"
import SafariLodge14 from "./../images/SafariLodge/IMG_14.jpg"
import SafariLodge15 from "./../images/SafariLodge/IMG_15.jpg"
import SafariLodge16 from "./../images/SafariLodge/IMG_16.jpg"
import SafariLodge17 from "./../images/SafariLodge/IMG_17.jpg"
import SafariLodge18 from "./../images/SafariLodge/IMG_18.jpg"
import SafariLodge19 from "./../images/SafariLodge/IMG_19.jpg"
import SafariLodge20 from "./../images/SafariLodge/IMG_20.jpg"
import SafariLodge21 from "./../images/SafariLodge/IMG_21.jpg"
import SafariLodge22 from "./../images/SafariLodge/IMG_22.jpg"
import SafariLodge23 from "./../images/SafariLodge/IMG_23.jpg"
import SafariLodge24 from "./../images/SafariLodge/IMG_24.jpg"
import SafariLodge25 from "./../images/SafariLodge/IMG_25.jpg"
import SafariLodge26 from "./../images/SafariLodge/IMG_26.jpg"
import SafariLodge27 from "./../images/SafariLodge/IMG_27.jpg"
import SafariLodge28 from "./../images/SafariLodge/IMG_28.jpg"
import SafariLodge29 from "./../images/SafariLodge/IMG_29.jpg"
import SafariLodge30 from "./../images/SafariLodge/IMG_30.jpg"
import SafariLodge31 from "./../images/SafariLodge/IMG_31.jpg"
import SafariLodge32 from "./../images/SafariLodge/IMG_32.jpg"
import SafariLodge33 from "./../images/SafariLodge/IMG_33.jpg"
import SafariLodge34 from "./../images/SafariLodge/IMG_34.jpg"
import SafariLodge35 from "./../images/SafariLodge/IMG_35.jpg"
import SafariLodge36 from "./../images/SafariLodge/IMG_36.jpg"
import SafariLodge37 from "./../images/SafariLodge/IMG_37.jpg"
import SafariLodge38 from "./../images/SafariLodge/IMG_38.jpg"
import SafariLodge39 from "./../images/SafariLodge/IMG_39.jpg"
import SafariLodge40 from "./../images/SafariLodge/IMG_40.jpg"
import SafariLodge41 from "./../images/SafariLodge/IMG_41.jpg"
import SafariLodge42 from "./../images/SafariLodge/IMG_42.jpg"
import SafariLodge43 from "./../images/SafariLodge/IMG_43.jpg"
import SafariLodge44 from "./../images/SafariLodge/IMG_44.jpg"

import AquaHouse1 from "./../images/AquaHouse/IMG_01.jpg"
import AquaHouse2 from "./../images/AquaHouse/IMG_02.jpg"
import AquaHouse3 from "./../images/AquaHouse/IMG_03.jpg"
import AquaHouse4 from "./../images/AquaHouse/IMG_04.jpg"
import AquaHouse5 from "./../images/AquaHouse/IMG_05.jpg"
import AquaHouse6 from "./../images/AquaHouse/IMG_06.jpg"
import AquaHouse7 from "./../images/AquaHouse/IMG_07.jpg"
import AquaHouse8 from "./../images/AquaHouse/IMG_08.jpg"
import AquaHouse9 from "./../images/AquaHouse/IMG_09.jpg"

const projects = [
    {
        projectName: "Sparta Beef Welkom Offices, South Africa",
        projectBrief: "Clean modern ergonomic space with natural light using an existing old building in an industrial complex that made the human enviroment inspiration and easy to use. Big open shared desking with user-friendly meeting rooms and hubs. Lovely eating, coffee areas and outdoor spaces.",
        images: [
            {
                imgSrc: sparta1,
                alt: "office kitchenette",
                displayOnMain: true
            },
            {
                imgSrc: sparta2,
                alt: "office workspaces",
                displayOnMain: false
            },
            {
                imgSrc: sparta3,
                alt: "office cubicles",
                displayOnMain: false
            },
            {
                imgSrc: sparta4,
                alt: "sparta beef display",
                displayOnMain: true
            },
            {
                imgSrc: sparta5,
                alt: "reception",
                displayOnMain: true
            },
            {
                imgSrc: sparta6,
                alt: "meeting room",
                displayOnMain: false
            },
            {
                imgSrc: sparta7,
                alt: "meeting room",
                displayOnMain: false
            },
            {
                imgSrc: sparta8,
                alt: "passageway",
                displayOnMain: true
            },
            {
                imgSrc: sparta9,
                alt: "kitchen",
                displayOnMain: true
            },
            {
                imgSrc: sparta10,
                alt: "decorated room",
                displayOnMain: true
            },
            {
                imgSrc: sparta11,
                alt: "meeting room",
                displayOnMain: true
            },
            {
                imgSrc: sparta12,
                alt: "Sparta beef entrance",
                displayOnMain: false
            }
        ]
    },
    {
        projectName: "6 Bedroom House, Oxshott, Surrey",
        projectBrief: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat fringilla magna, ullamcorper tincidunt nulla cursus ut. Vestibulum placerat commodo iaculis.",
        images: [
            {
                imgSrc: Oxshott6Bed1,
                alt: "bathroom",
                displayOnMain: true
            },
            {
                imgSrc: Oxshott6Bed2,
                alt: "Master bedroom",
                displayOnMain: true
            },
            {
                imgSrc: Oxshott6Bed3,
                alt: "stairs and hall",
                displayOnMain: false
            },
            {
                imgSrc: Oxshott6Bed4,
                alt: "Living room",
                displayOnMain: false
            },
            {
                imgSrc: Oxshott6Bed5,
                alt: "kitchen",
                displayOnMain: true
            },
            {
                imgSrc: Oxshott6Bed6,
                alt: "entrance hall",
                displayOnMain: true
            },
            {
                imgSrc: Oxshott6Bed7,
                alt: "cinema room",
                displayOnMain: false
            },
            {
                imgSrc: Oxshott6Bed8,
                alt: "study",
                displayOnMain: false
            },
            {
                imgSrc: Oxshott6Bed9,
                alt: "study",
                displayOnMain: false
            },
            {
                imgSrc: Oxshott6Bed10,
                alt: "Living room",
                displayOnMain: true
            },
            {
                imgSrc: Oxshott6Bed11,
                alt: "dressing room",
                displayOnMain: true
            },
            {
                imgSrc: Oxshott6Bed12,
                alt: "dining room",
                displayOnMain: true
            },
            {
                imgSrc: Oxshott6Bed13,
                alt: "living room",
                displayOnMain: true
            },
            {
                imgSrc: Oxshott6Bed14,
                alt: "patio",
                displayOnMain: false
            },
            {
                imgSrc: Oxshott6Bed15,
                alt: "display",
                displayOnMain: false
            }
        ]
    },
    {
        projectName: "2 Bedroom Apartment, Mauritius",
        projectBrief: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat fringilla magna, ullamcorper tincidunt nulla cursus ut. Vestibulum placerat commodo iaculis.",
        images: [
            {
                imgSrc: Mauritius2Bed1,
                alt: "Dining area",
                displayOnMain: false
            },
            {
                imgSrc: Mauritius2Bed2,
                alt: "Bedroom",
                displayOnMain: true
            },
            {
                imgSrc: Mauritius2Bed3,
                alt: "Bedroom",
                displayOnMain: false
            },
            {
                imgSrc: Mauritius2Bed4,
                alt: "Living room",
                displayOnMain: true
            },
            {
                imgSrc: Mauritius2Bed5,
                alt: "Master bedroom",
                displayOnMain: true
            },
            {
                imgSrc: Mauritius2Bed6,
                alt: "Outdoor living space",
                displayOnMain: true
            },
            {
                imgSrc: Mauritius2Bed7,
                alt: "Patio",
                displayOnMain: false
            },
            {
                imgSrc: Mauritius2Bed8,
                alt: "Outdoor living space",
                displayOnMain: true
            },
            {
                imgSrc: Mauritius2Bed9,
                alt: "living room",
                displayOnMain: false
            },
            {
                imgSrc: Mauritius2Bed10,
                alt: "bathroom",
                displayOnMain: true
            },
            {
                imgSrc: Mauritius2Bed11,
                alt: "outdoor living space",
                displayOnMain: false
            },
            {
                imgSrc: Mauritius2Bed12,
                alt: "master bedroom and bathroom",
                displayOnMain: true
            },
            {
                imgSrc: Mauritius2Bed13,
                alt: "patio",
                displayOnMain: true
            },
            {
                imgSrc: Mauritius2Bed14,
                alt: "patio",
                displayOnMain: false
            }
        ]
    },
    {
        projectName: "Safari Lodge, South Africa",
        projectBrief: "Preplanning and final styling",
        images: [
            {
                imgSrc: SafariLodge1,
                alt: "Dining table",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge2,
                alt: "Bedroom",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge3,
                alt: "sink",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge4,
                alt: "bedroom",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge5,
                alt: "sink",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge6,
                alt: "shower",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge7,
                alt: "fireplace",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge8,
                alt: "fireplace",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge9,
                alt: "bedroom",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge10,
                alt: "bedroom",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge11,
                alt: "living area coffee table",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge12,
                alt: "bedroom",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge13,
                alt: "decorations",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge14,
                alt: "tv living space",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge15,
                alt: "Lodge exterior",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge16,
                alt: "sitting area",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge17,
                alt: "bedroom",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge18,
                alt: "bathroom",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge19,
                alt: "bathroom",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge20,
                alt: "display",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge21,
                alt: "display",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge22,
                alt: "outside table",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge23,
                alt: "bedroom",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge24,
                alt: "bedroom",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge25,
                alt: "bedroom",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge26,
                alt: "coffee table",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge27,
                alt: "bedroom",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge28,
                alt: "bedroom",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge29,
                alt: "sitting area",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge30,
                alt: "bathroom",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge31,
                alt: "bedroom table",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge32,
                alt: "fireplace",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge33,
                alt: "dressing table",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge34,
                alt: "bedroom",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge35,
                alt: "dining area",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge36,
                alt: "dining table decorations",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge37,
                alt: "bar",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge38,
                alt: "bar equipment",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge39,
                alt: "coffee table",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge40,
                alt: "deck chairs by the pool",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge41,
                alt: "decoration",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge42,
                alt: "fireplace, living room",
                displayOnMain: true
            },
            {
                imgSrc: SafariLodge43,
                alt: "Lodge exterior",
                displayOnMain: false
            },
            {
                imgSrc: SafariLodge44,
                alt: "Lodge exterior",
                displayOnMain: false
            }
        ]
    },
    {
        projectName: "Aqua House, South Africa",
        projectBrief: "Farmhouse",
        images: [
            {
                imgSrc: AquaHouse1,
                alt: "display",
                displayOnMain: false
            },
            {
                imgSrc: AquaHouse2,
                alt: "sink",
                displayOnMain: true
            },
            {
                imgSrc: AquaHouse3,
                alt: "entry hall display table",
                displayOnMain: false
            },
            {
                imgSrc: AquaHouse4,
                alt: "bathroom",
                displayOnMain: false
            },
            {
                imgSrc: AquaHouse5,
                alt: "bathroom",
                displayOnMain: true
            },
            {
                imgSrc: AquaHouse6,
                alt: "childs bedroom",
                displayOnMain: true
            },
            {
                imgSrc: AquaHouse7,
                alt: "hallway",
                displayOnMain: false
            },
            {
                imgSrc: AquaHouse8,
                alt: "tv room",
                displayOnMain: false
            },
            {
                imgSrc: AquaHouse9,
                alt: "living room display table",
                displayOnMain: true
            }
        ]
    }
];

export default projects;