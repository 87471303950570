import React from 'react'

const LogoSVG = ({className, onClick}) => {
    return (
        <svg version="1.0" viewBox="0 0 780.000000 386.000000" preserveAspectRatio="xMidYMid meet" className={className} onClick={onClick} >
            <g color="#000000" transform="translate(0.000000,386.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M50 1935 l0 -1855 3850 0 3850 0 0 1855 0 1855 -3850 0 -3850 0 0 -1855z m7590 0 l0 -1745 -3740 0 -3740 0 0 1745 0 1745 3740 0 3740 0 0 -1745z"/>
                <path d="M1323 3459 c-205 -26 -384 -125 -468 -259 -66 -105 -90 -262 -61 -400 53 -247 200 -349 691 -475 444 -114 561 -193 596 -404 35 -208 -96 -379 -337 -441 -90 -24 -289 -29 -384 -10 -230 44 -382 165 -455 363 -10 26 -20 47 -23 47 -2 0 -40 -10 -84 -22 l-80 -22 6 -30 c9 -42 58 -146 94 -199 110 -159 304 -267 538 -298 125 -16 347 -7 449 20 188 49 341 160 402 291 42 89 54 147 54 254 -1 148 -43 269 -122 346 -47 45 -155 118 -214 143 -57 24 -236 79 -399 122 -482 127 -586 209 -574 454 3 70 10 99 32 144 50 102 159 179 303 213 98 23 332 15 424 -15 168 -54 278 -159 328 -313 10 -31 16 -37 34 -33 12 2 49 9 81 15 33 5 61 12 64 14 9 9 -40 134 -76 195 -72 124 -231 236 -386 276 -130 33 -296 42 -433 24z"/>
                <path d="M3637 3455 c-42 -8 -113 -29 -156 -45 -429 -166 -652 -687 -556 -1297 55 -343 241 -612 510 -734 130 -58 212 -73 410 -73 154 0 192 4 260 22 203 56 357 167 454 326 26 44 91 187 91 201 0 3 -145 30 -163 30 -5 0 -21 -29 -36 -65 -38 -89 -62 -125 -123 -188 -168 -172 -464 -226 -744 -138 -336 107 -523 469 -500 971 14 314 111 550 286 693 62 51 187 113 270 133 82 21 319 19 397 -3 201 -56 332 -177 407 -379 l18 -46 71 14 c105 21 99 15 78 75 -46 133 -125 253 -219 331 -75 63 -212 132 -309 158 -105 27 -336 35 -446 14z"/>
                <path d="M5380 2384 l0 -1055 453 4 c503 5 528 7 664 73 188 90 282 273 270 524 -10 199 -76 315 -232 407 -56 33 -167 73 -202 73 -39 0 -26 15 25 29 71 19 166 76 211 127 80 91 113 194 112 354 0 168 -45 287 -140 372 -136 123 -278 148 -833 148 l-328 0 0 -1056z m878 883 c184 -54 262 -164 262 -367 0 -182 -64 -297 -199 -361 -85 -40 -174 -49 -482 -49 l-289 0 0 401 0 401 328 -5 c257 -3 338 -8 380 -20z m-3 -942 c140 -30 250 -106 300 -209 30 -62 49 -190 40 -265 -25 -202 -128 -315 -323 -357 -50 -10 -151 -14 -394 -14 l-328 0 0 430 0 430 318 0 c241 0 334 -4 387 -15z"/>
                <path d="M2485 961 c-109 -28 -165 -102 -165 -216 0 -97 35 -157 120 -201 26 -13 58 -18 120 -19 75 0 93 4 152 32 36 17 69 29 72 25 16 -16 3 17 -21 51 -24 34 -28 36 -34 19 -4 -10 -30 -28 -57 -40 -131 -57 -262 10 -262 133 0 96 77 160 182 152 64 -6 117 -31 134 -65 11 -22 12 -21 38 19 15 22 26 42 24 43 -103 53 -149 69 -208 72 -36 2 -78 0 -95 -5z"/>
                <path d="M3630 890 c0 -6 -62 -10 -165 -10 -161 0 -165 -1 -155 -19 5 -11 10 -81 10 -156 0 -75 -5 -145 -10 -156 -10 -18 -6 -19 159 -19 126 0 171 -3 174 -12 3 -7 5 12 5 42 0 30 -2 49 -5 43 -3 -10 -38 -13 -124 -13 l-119 0 0 45 0 45 74 0 c50 0 76 -4 79 -12 3 -7 5 12 5 42 0 30 -2 49 -5 43 -3 -9 -29 -13 -79 -13 l-74 0 0 40 0 40 114 0 c82 0 116 -4 119 -12 3 -7 6 11 6 40 1 28 -1 52 -4 52 -3 0 -5 -4 -5 -10z"/>
                <path d="M4132 850 c0 -30 2 -49 5 -42 3 8 26 12 64 12 l59 0 0 -133 c0 -90 -4 -137 -12 -145 -9 -9 2 -12 50 -12 59 0 62 1 52 19 -5 11 -10 76 -10 145 l0 126 58 0 c32 0 63 -5 70 -12 9 -9 12 0 12 42 0 42 -3 51 -12 42 -17 -17 -324 -17 -331 1 -3 6 -5 -13 -5 -43z"/>
                <path d="M5455 890 c-4 -6 -73 -10 -173 -11 -92 0 -161 -3 -154 -6 9 -3 12 -47 12 -168 0 -121 -3 -165 -12 -168 -7 -3 64 -6 158 -6 118 -1 174 -5 182 -13 9 -9 12 0 12 42 0 42 -3 51 -12 42 -8 -8 -50 -12 -125 -12 l-113 0 0 45 0 45 68 0 c40 0 73 -5 80 -12 9 -9 12 0 12 42 0 42 -3 51 -12 42 -7 -7 -40 -12 -80 -12 l-68 0 0 40 0 40 108 0 c71 0 112 -4 120 -12 9 -9 12 0 12 40 0 52 -3 61 -15 42z"/>
                <path d="M2870 861 c5 -11 10 -81 10 -156 0 -75 -5 -145 -10 -156 -10 -18 -7 -19 52 -19 48 0 59 3 50 12 -7 7 -12 39 -12 76 l0 63 42 -3 c39 -3 46 -8 81 -58 21 -31 36 -63 33 -72 -4 -16 4 -18 65 -18 l69 0 -55 68 c-30 37 -55 70 -55 74 0 4 15 14 34 22 40 17 56 42 56 86 0 82 -41 100 -230 100 -136 0 -140 -1 -130 -19z m270 -60 c13 -25 13 -27 -6 -45 -11 -12 -36 -16 -95 -16 l-79 0 0 40 0 40 85 0 c73 0 86 -3 95 -19z"/>
                <path d="M3839 863 c7 -13 -8 -51 -61 -158 -39 -77 -77 -148 -86 -157 -14 -17 -11 -18 53 -18 59 0 66 2 55 15 -10 12 -10 21 0 45 12 30 13 30 95 30 82 0 83 0 95 -30 10 -24 10 -33 0 -45 -11 -13 -3 -15 60 -15 69 0 72 1 58 18 -9 9 -48 78 -88 151 -51 97 -70 141 -65 158 5 22 3 23 -60 23 -59 0 -65 -2 -56 -17z m83 -143 l28 -50 -54 0 -55 0 21 50 c11 28 23 50 27 50 3 0 18 -23 33 -50z"/>
                <path d="M4530 861 c5 -11 10 -81 10 -156 0 -75 -5 -145 -10 -156 -10 -18 -7 -19 52 -19 48 0 59 3 50 12 -8 8 -12 59 -12 163 0 104 4 155 12 163 9 9 -2 12 -50 12 -59 0 -62 -1 -52 -19z"/>
                <path d="M4682 863 c9 -10 46 -81 83 -158 53 -112 64 -143 56 -157 -10 -17 -5 -18 61 -17 40 0 67 3 61 6 -7 2 -13 13 -13 23 0 24 118 269 143 298 l20 22 -71 0 c-68 0 -72 -1 -60 -19 10 -16 4 -35 -33 -115 -24 -53 -46 -96 -49 -96 -3 0 -25 44 -48 97 -35 78 -42 101 -33 115 10 17 5 18 -60 18 -68 0 -71 -1 -57 -17z"/>
            </g>
        </svg>
    )
}

export default LogoSVG;